import React from 'react'
import SliderProduct from '../../component/slidercomponent/SliderProduct'
import { Link } from "react-router-dom";


const Overview = () => {
  return (
    <>
      <div>
        <section className="page-title">
          <div className="content" style={{ backgroundImage: 'url(./assets/public/website/assets/images/background/journey.jpg)' }}>
            <div className="auto-container">
              {/* <h1>Process</h1> */}
            </div>
          </div>
          <ul className="page-breadcrumb">
            <li><Link to="/">home</Link></li>
            <li> Overview</li>
          </ul>
        </section>


        <div className="container">
          <div className="row">
            <div className="col-lg-12 processfst_outsection">

              <div className="banner">
                <h1 className="textabtn_Design">
                  Overview
                </h1>
              </div>
              <p className="rawmaterial_pgetext">
                We ADARSH SALES PVT. LTD. is one of the premier
                manufacturer and supplier of PLYWOOD, DOORS & BLOCKBOARDS is known for its extraordinary quality of products and
                exceptional service to its clients, distributors, and dealers. With
                the intention of quality first A-ADARSH PLY has come to
                represent a trusted brand with immaculate product integrity and
                market goodwill. Today, the company has a rock-solid foundation
                of state-of-the-art machinery and a devoted nationwide network of
                offices, distributors, dealers.
              </p>
              <p className="rawmaterial_pgetext">
                The business commenced with the establishment of our firm
                “Adarsh Furniture Centre” on November 13, 1982.
                Adarsh Furniture Centre is the oldest Furniture shop in the Satna
                district. Since 1982 we maintain our dignity and discipline in
                manufacturing top-quality products. With the increase in demand
                for Plywood and Flush Doors, Adarsh Sales Pvt. Ltd. was
                incorporated in 2009 to grab the opportunity to provide the best
                calibre products to the market.
              </p>

            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 processfst_outsection">
              <div className="row">
                <div className="col-lg-7">
                  <div className="processrightfst_outsection">

                    <div className="banner">
                      {/* <h1 className="textabtn_Design">
                                                Company Profile
                                            </h1> */}
                    </div>
                    <p className="rawmaterial_pgetext">
                      Since the origin of our organization, it has been. our undertaking
                      to stay at the cutting edge of advancement, and embrace the
                      changing inclinations of the Indian shopper wholeheartedly. It is
                      thus that we have continually endeavoured to add new items and
                      administrations to our current item offering. We furnish our clients
                      with a believed wellspring of top calibre, convenient conveyance
                      and novel arrangements made with manageability. We trust in building dependable connections by offering greatness in
                      assistance.
                    </p>
                    <p className="rawmaterial_pgetext">
                      We accept the fact that "A house is a fantasy that needs to come
                      true". We endeavour to give our clients the Best-in-Performance
                      items that are reasonably sourced, top-notch, and stylishly
                      engaging so their fantasies work out.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="processleftfst_outsection">
                    <img src="./assets/public/website/assets/images/resource/process-11.gif" className="fposter_uimgdesign" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12 processfst_outsection">
              <div className="row">
                <div className="col-lg-5">
                  <div className="processleftfst_outsection">
                    <img src="./assets/public/website/assets/images/resource/process-12.gif" className="fposter_uimgdesign" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="processrightfst_outsection">

                    <div className="banner">
                      {/* <h1 className="textabtn_Design">
                                                Research &amp; Development
                                            </h1> */}
                    </div>
                    <p className="rawmaterial_pgetext">
                      The company’s products occupy an enviable position in the
                      Indian marketplace and have multifarious applications – Ranging
                      from household to buildings to construction. It is one of those
                      plywood company that believes in Consumer Satisfaction. The
                      company’s in-house research and technology ensures not just
                      consistency in product quality but also continuous improvements
                      in products, processes, and application areas. Our R&D ensures
                      constant improvement of existing product and innovation of new
                      ones to offer better, offer more to our customer. Moreover, our
                      process is eco-friendly and energy-efficient with an in-house
                      seasoning plant, measures that ensure the health and safety of
                      our manpower. Our in-house production of resin helps in stronger
                      bonding and increase the durability of the product and making the
                      product waterproof.
                    </p>
                    <p className="rawmaterial_pgetext">
                      We believe that quality is not an act, it is a habit, and throughout
                      our journey, we only focus on manufacturing quality products.
                      Each batch that bears the A-ADARSH name is checked as per
                      BIS norms and EUROPEAN STANDARDS before being released
                      in the market. At A-Adarsh Ply we have always believed that our
                      quality and service will help us succeed. Adarsh ply products
                      enjoy the trust, confidence and patronage of all their customers
                      and influencers. Our affiliation, association and dedication to
                      quality procedures and willingness to innovate and be the best
                      have carved a niche in the hearts and mind space of all our
                      customers and trade partners.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p className="rawmaterial_pgetext">
            We ADARSH SALES PVT. LTD. is one of the premier
            manufacturer and supplier of PLYWOOD, DOORS & BLOCKBOARDS is known for its extraordinary quality of products and
            exceptional service to its clients, distributors, and dealers. With
            the intention of quality first A-ADARSH PLY has come to
            represent a trusted brand with immaculate product integrity and
            market goodwill. Today, the company has a rock-solid foundation
            of state-of-the-art machinery and a devoted nationwide network of
            offices, distributors, dealers.
          </p>
          <p className='rawmaterial_pgetext'>Today A- ADARSH PLY is the unprecedented choice of
            Architects, Interior Designer & contractors. They strongly
            recommend our products for transforming ordinary steel and
            concrete structure to the expression of one’s personality. We are
            guided by a simple philosophy of adding more value for money,
            products and at the same time, improving upon our existing
            product portfolio through extensive research and most important
            costumer’s feedback. Every product is strictly inspected because:
            “WE NEVER COMPROMISE WITH QUALITY”.</p>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-12 processfst_outsection">
              <div className="row">
                <div className="col-lg-7">
                  <div className="processrightfst_outsection">

                    <div className="banner">
                      <h1 className="textabtn_Design">
                        Dedicated Channel Partners
                      </h1>
                    </div>
                    <p className="rawmaterial_pgetext">
                      Our dealers and retailers are the ones who have made the products available far and
                      wide for our esteemed customers. In addition to contributing to our growing
                      prosperity, these people have also enabled us to spread our wings to various parts
                      of India and build a credible brand name all over the country.
                    </p>
                    <p className="rawmaterial_pgetext">
                      On the other hand, we make sure that this effective alliance is as fruitful for them
                      as it is for us with consistent quality. Our channel partners have been catering to
                      the market demands with tremendous dedication. We make sure to establish unbreakable
                      bonds with them by supporting each other and contributing to each other’s growth so
                      that both parties can enjoy a lifelong relationship filled with integrity and
                      sincerity.
                    </p>
                    <p className="rawmaterial_pgetext">
                      Consequently, more and more people are joining our Sylvan Ply family by becoming
                      dealers and retailers of this eminent brand. Along with possessing the traits of
                      being passionate, committed, and earnest, every individual in our extended family
                      has a strong will to strive for success in their professional life.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="processleftfst_outsection">
                    <img src="./assets/public/website/assets/images/resource/process-13.gif" className="fposter_uimgdesign" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 processfst_outsection">
              <div className="row">
                <div className="col-lg-5">
                  <div className="processleftfst_outsection">
                    <img src="./assets/public/website/assets/images/resource/process-14.gif" className="fposter_uimgdesign" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="processrightfst_outsection">

                    <div className="banner">
                      <h1 className="textabtn_Design">
                        Loyal Clients
                      </h1>
                    </div>
                    <p className="rawmaterial_pgetext">
                      Sylvan Ply has become one of the top choices in terms of optimum quality plywood
                      while it is the cordial cooperation of our clients that has inspired us to move
                      forward with a heartful of energy to bring out the best service in the present
                      competitive market. As a result, absolute professionalism is what people have come
                      to expect from us. We strive to continue introducing the best products to oblige the
                      requirements of all our customers with utmost efficiency and live up to all our
                      promises.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 processfst_outsection">
              <div className="row">
                <div className="col-lg-7">
                  <div className="processrightfst_outsection">

                    <div className="banner">
                      <h1 className="textabtn_Design">
                        Eco-friendly Ply
                      </h1>
                    </div>
                    <p className="rawmaterial_pgetext">
                      Sylvan Plyboard India Ltd. is widely adulated for its eco-friendly products and
                      choices. We predominantly focus on environmentally-conscious decisions at every step
                      of the way. Our distinguishing feature lies in our persistent and unrelenting
                      resolve to make decisions while keeping the environment in mind.
                    </p>
                    <p className="rawmaterial_pgetext">
                      Our credibility is built upon years of experience and dedicated research. We
                      implement the usage of suitable pollution control devices like dust filter(s) and
                      back filter(s), which reduces the emission of harmful gases such as carbon, etc.
                      into the atmosphere and removes all dust particles that are generated during the
                      process of cutting and sanding.
                    </p>
                    <p className="rawmaterial_pgetext">
                      We also practice judicious use of water as we are aware of the fact that water is
                      one of the essential natural resources. The Effluent Water Treatment Plant is a
                      significant device in use at our plant that contributes to our pursuit of being
                      absolutely eco-friendly. It treats and recycles water, which had been contaminated
                      during various phases of the manufacturing process.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="processleftfst_outsection">
                    <img src="./assets/public/website/assets/images/resource/process-15.gif" className="fposter_uimgdesign" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="prcoessdown_outsection">
                    <p className="rawmaterial_pgetext">
                      Furthermore, it is an excellent technique to reuse wastewater or assure its safe
                      disposal in the environment. Additionally, we at Sylvan Ply, are constantly
                      researching in order to innovate new strategies, which would be instrumental in our
                      bid to remain eco-friendly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}


        <div className="container">
          <div className="row">
            <div className="col-lg-12 processfst_outsection">
              <div className="row">
                <div className="banner">
                  <h1 className="textabtn_Design">Our  Mission & Vision</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 prcsx_outsection">
              <div className="row">
                <div className="col-lg-6">
                  <div className="leftprcsx_leftosection">
                    {/* <h2 className="procesheadng_text">Mission</h2> */}
                    <img src="./assets/ply/Mission.JPG" alt="Awesome Logo" />

                    <p className="prcoess_lnddtext">
                      To build the best product. To be the first preference of customers in
                      terms of quality, finish and durability. To prove to the market that quality will always be preferred over price.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="leftprcsx_leftosection">
                    {/* <h2 className="procesheadng_text">Vision</h2> */}
                    <img src="./assets/ply/Vision.png" alt="Awesome Logo" />
                    <p className="prcoess_lnddtext">
                      To provide a world-class lifestyle experience to the clients. To be on the top of the market in terms of technology, advanced machinery and continuous innovation and research.
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="clients-section ingallery_psection">
          <div className="auto-container">
            <div className="inner-container">
              <div className="sponsors-outer">
                <ul className="sponsors-carousel owl-theme">
                  <SliderProduct />
                </ul>
              </div>
            </div>
          </div>
        </section>


        {/* <div className="container">
          <div className="row">
            <div className="col-lg-12 prcsx_outsection">
              <div className="row">
                <div className="col-lg-6">
                  <div className="leftprcsx_leftosection">
                    <h2 className="procesheadng_text">Mission</h2>
                    <p className="prcoess_lnddtext">
                      We continuously endeavour to enhance our products with constant innovation and use of latest technology to serve our costumer with best quality. We have maintained our product quality standards, ever since our inception.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="leftprcsx_leftosection">
                    <h2 className="procesheadng_text">Vision</h2>
                    <p className="prcoess_lnddtext">
                    To be the most admired wood based company through innovation, hard work and excellence in customer service.
                    </p>
                    <br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 processfst_outsection">
              <div className="row">
                <div className="col-lg-5">
                  <div className="processleftfst_outsection">
                    <img src="./assets/public/website/assets/images/resource/process-15.gif" className="fposter_uimgdesign" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="processrightfst_outsection">

                    <div className="banner">
                      <h1 className="textabtn_Design">
                        Our Factories
                      </h1>
                    </div>
                    <p className="rawmaterial_pgetext">
                      The factory of Sylvan Ply is located at Baidyabati, Shrirampore, which is 45 Km from
                      Kolkata. Equipped with the latest and highly advanced machinery and equipment, our
                      factory is spread over a sprawling 100 acres. Apart from offering some of the most
                      distinguishing features and innovative technologies, we proffer meticulous attention
                      to detail every step of the way. Since our inception, we have procured extensive
                      experience in every aspect of the production process and have incorporated necessary
                      changes for the betterment of our services.
                    </p>
                    <p className="rawmaterial_pgetext">
                      We adhere to stringent quality control norms at every stage, which ensures that our
                      ply is free from all kinds of impurities and microorganisms. It results in one of
                      the most superior and finest quality plywood, which is at par with International
                      Standards. We imprint our sign on each and every product with a great sense of
                      achievement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 processfst_outsection">
              <div className="row">
                <div className="col-lg-7">
                  <div className="processrightfst_outsection">
                    <div className="banner">
                      <h1 className="textabtn_Design">
                        Our History
                      </h1>
                    </div>
                    <p className="rawmaterial_pgetext">
                      The Adarsh Group Came Into Existence In The Year 1982 Under The Aegis Of Shri. Hanuman Prasad Gupta, A Leader, A Visionary And A Philanthropist. The Group Began It’s Operation By Stepping Into The Furniture Industry By The Name Of Adarsh Furniture Centre. Impelled By The Soul Of Consistent Development, Advancement, Uphelp By Promise To Greatness , The Group Todat Is A Multi Dimentional Organization With A Solid Nearness In Segments As Different As – Furniture, Plywood & Flush Doors, And Interior Designing And Execution.
                      Anticipating Future Increased Market Demand For Plywood, Flush Door, And Block Boards, The Group Diversified Itself And Laid Its Manufacturing Ply, Doors And Boards And Their Allied Products.
                    </p>
                    <p className="rawmaterial_pgetext">
                      That’s When Adarsh Sales Pvt. Ltd. Was Started In 2009 And Being The Game Changer In Flushdoor And Plywood Industry. The Company Is Commited Towards Introducing Innovative Products To The Indian Interior Infrastructure Industry. A Perfect Bend Of Specialized Prevalence Joined With A Wide Scope Of Structure Spread Over A Rich Palette Settle On  Adarsh Items A Perfect Descision.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="processleftfst_outsection mt-lg-5">
                    <br />
                    <br />
                    <img src="./assets/public/website/assets/images/resource/movingtruck.gif" className="mvngtruck_Design" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>

    </>
  )
}

export default Overview