import React from 'react'
import { Link } from "react-router-dom";


const FlushDoorPremium = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 aboutus_outsection">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="leftimg_outsection mt-lg-5">
                                        <img src="./assets/public/website/assets/images/resource/about_1.png" className="abtus_imgdesign" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="righttime_outsection">
                                        <h1 className="productzx_nmetextdesign">
                                            FLUSH DOOR: PREMIUM (IS:2202(PT-1)1999)
                                        </h1>
                                        <p className="aboutus_content">
                                        </p>
                                        <p className="aboutus_content">Our PREMIUM segment Flush Doors comes as per IS: 2202 PT-1 (1999) and BWP grade.
                                            It is constructed from seasoned and chemically treated Hard Wood. Fillers are also well
                                            settled and to absorb the right amount of resin and provide a smooth surface. We also
                                            provide an extra lock rail on both sides as extra support for modern technology locks.
                                            The top surface is finished with a high-quality Gurjan face. Sanding and Dipping, in the
                                            end, provide excellent finish and chemical treatment.</p>



                                    </div>
                                </div>

                                <div className="col-lg-12 kftrs_outsection">
                                    <h1 className="rtrti_text">
                                        Unique features:
                                    </h1>
                                    <p className="sizethckns_design">
                                    </p>


                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="">

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Boiling waterproof </p>
                                                            <p>➤ No warping or bending</p>
                                                            <p>➤ Fungal, termite and borer treated </p>
                                                            <p>➤ Superior Stability and durability </p>

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Dimensional accuracy</p>
                                                            <p>➤ High stability strength</p>
                                                            <p>➤ High shock and buckling resistance </p>
                                                            <p>➤ Chemically treated and seasoned
                                                                as per BIS</p>
                                                            <p>➤ Zero defects in flexure and edge
                                                                loading</p>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 className="rtrti_text1">
                                        Specifications
                                    </h1>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="">

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Thickness available : 30mm to 45mm</p>
                                                            <p>➤ Size available : Available in all standard sizes</p>
                                                            <p>➤ Frame width : 75mm</p>
                                                            <p>➤ Lock rail width : 75mm (both side)</p>
                                                            <p>➤ Adhesive for bonding : PF resin as per BIS standards. </p>
                                                            <p>➤ Density : 700-750 kg/cm3</p>




                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Moisture content : 9-13% </p>
                                                            <p>➤ Glue adhesion strength :  Excellent</p>
                                                            <p>➤ Resistance to misuse  : Excellent</p>
                                                            <p>➤  Water-resistance test  : Confirms to IS:2202(pt-1) 1999 </p>
                                                            <p>➤ Warranty   : Any manufacturing defects as per warranty
                                                                certificate. </p>
                                                            <p>➤ IS STANDARDS : IS:2202 (PT-1) 1999 </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h1 className="rtrti_text2">
                                        End Usage: Doors
                                    </h1>
                                    <ul className="spcgh_area">
                                        {/* <li>
                                            <img src="../assets/public/storage/productends/January2024/HcNGY47FiqsJV3LAA7Ma.png" alt="Commercial Doors" />
                                            <span>END USAGE: DOORS</span>
                                        </li> */}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </section>
        </>
    )
}

export default FlushDoorPremium