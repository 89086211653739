import React from 'react'
import { Link } from "react-router-dom";
import SupremePly from './SupremePly';


const Plywood = () => {
  return (
    <>

      <div className=''>
        <section className="page-title">
          <div className="content" style={{ backgroundImage: 'url("./assets/public/website/assets/images/background/products.jpg")' }}>
          </div>
          <ul className="page-breadcrumb">
            <li><Link to="/">home</Link></li>
            <li>Product Details</li>
          </ul>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 prodcdtls_outsection">
              <div className="sky-tabs sky-tabs-pos-top-left sky-tabs-anim-flip sky-tabs-response-to-icons">
                <input type="radio" name="sky-tabs" defaultChecked id="sky-tab1" className="sky-tab-content-1" />
                <label className="switcher"><span><span><a to="#"><i className="icon-reorder" />Tabs</a></span></span></label>
                <ul>
                  <li className="sky-tab-content-1">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="productdtls_sfvoutsection">
                            <div className="product-imgs">
                              <div className="img-display">
                                <div className="img-showcase">
                                  <img src="../assets/ply/plywood-500x500.webp" alt="shoe image" />
                                  {/* <img src="../assets/public/storage/plywoodproducts/February2024/qqSFxjWeMG2HvepQYubI.png" alt="shoe image" /> */}
                                  {/* <img src="../assets/public/storage/plywoodproducts/February2024/BP2BTT6dgD5PvSC2Czwj.jpg" alt="shoe image" /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="productdtls_trgsection">
                            <h1 className="productzx_nmetextdesign">
                              Sandwich Ply (IS:303) – Fully Waterproof
                            </h1>
                            <p className="lzpum_textdesign">
                              SANDWICH Ply is considered to be the most exclusive product of ASPL. The
                              multi-use of the item makes it quite possibly the most favoured brands on
                              the lookout. Imported high-thickness lumber adds additional thickness and
                              thickness to the item, which gives better toughness and dimensional
                              security. Plus this plywood contains the properties of HDHMR and the
                              strength of plywood, which makes it carpenter friendly and popular in the
                              market. This handle is bubbling waterproof, which expands the life span of
                              the utilize. The sandwich series are being praised well by our clients.

                            </p>
                            {/* =======================points====== */}
                            <ul className="bthside_fgapareaprdctdtls">
                              <li>
                                {/* <img src="assets/images/resource/protect.png"> */}
                                <span>
                                  07 Year Guarantee
                                </span>
                              </li>
                              <li>
                                {/* <img src="assets/images/resource/protect.png"> */}
                                <span>
                                  Fully Water Proof
                                </span>
                                <img src="../assets/public/website/assets/images/resource/check-markr.png" className="redclrgap_design" />
                              </li>
                              <li>
                                {/* <img src="assets/images/resource/protect.png"> */}
                                <span>
                                  IS : 303 </span>
                              </li>

                            </ul>
                            {/* =======================points====== */}


                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 kftrs_outsection">
                          <h1 className="rtrti_text1">
                            Unique Features
                          </h1>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="specitwo_outarea">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="specitwo_outarea">
                                      <p>➤ Boiling waterproof </p>
                                      <p>➤ Smooth surface </p>
                                      <p>➤ Face thickness 3mm or 5mm</p>
                                      <p>➤ Chemically treated and seasoned as per BIS</p>
                                      <p>➤ Properties of HDHMR and strength of plywood </p>
                                      <p>➤ No warping or bending  </p>

                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="specitwo_outarea">
                                      <p>➤ Fungal, termite and borer treated </p>
                                      <p>➤ Superior Stability and durability </p>
                                      <p>➤  Absorbs less adhesive </p>
                                      <p>➤ Easy to paint</p>
                                      <p>➤ Dimensional accuracy  </p>
                                      <p>➤ High stability strength  </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h1 className="rtrti_text1">
                            Specifications
                          </h1>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="specitwo_outarea">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="specitwo_outarea">
                                      <p>➤ Thickness available :- 19mm & 12mm (more up to 40mm) </p>
                                      <p>➤ Size available :- Available in all standard sizes </p>
                                      <p>➤ Face:-  HDHMR</p>
                                      <p>➤ Core :-  All red hard core </p>
                                      <p>➤ Adhesive for bonding :-  PF resin as per BIS standards</p>
                                      <p>➤ Density :-  Above 750 kg/cm3</p>


                                    </div>
                                  </div>

                                  <div className="col-lg-6">
                                    <div className="specitwo_outarea">
                                      <p>➤ Moisture content :-  8-12%</p>
                                      <p>➤ Glue adhesion strength :- Excellent</p>
                                      <p>➤ Resistance to misuse :- Excellent</p>
                                      <p>➤ Water-resistance test :- Confirms to IS:303 </p>
                                      <p>➤ Warranty :- Any manufacturing defects as per warranty certificate. </p>
                                      <p>➤ IS STANDARDS :- IS:303 </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h1 className="rtrti_text2">
                            End Usages :- Furniture, Partitions, Ship Floor,
                            Wall Panelling, Boat Building,
                            Kitchens, Racks, Shelves Etc.
                          </h1>
                          <ul className="spcgh_area">
                            <li>
                              <img src="../assets/public/storage/productends/January2024/HcNGY47FiqsJV3LAA7Ma.png" alt="Commercial Doors" />
                              <span>Commercial Ply Wood</span>
                            </li>
                            <li>
                              <img src="../assets/public/storage/productends/January2024/L7LRXxKuA1XtG0yYOzkX.png" alt="Residential Doors" />
                              <span>Residential Ply Woods</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SupremePly />
    </>
  )
}

export default Plywood