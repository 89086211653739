import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";



export default function SliderProduct() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><Link to="/gallery"><img src="./assets/public/website/assets/images/gallery/g1.jpg" alt /></Link></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><Link to="/gallery"><img src="./assets/public/website/assets/images/gallery/g2.jpg" alt /></Link></figure>
                    </li>
                </SwiperSlide>
              
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><Link to="/gallery"><img src="./assets/public/website/assets/images/gallery/g5.jpg" alt /></Link></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><Link to="/gallery"><img src="./assets/public/website/assets/images/gallery/g9.jpg" alt /></Link></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><Link to="/gallery"><img src="./assets/public/website/assets/images/gallery/g10.jpg" alt /></Link></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><Link to="/gallery"><img src="./assets/public/website/assets/images/gallery/g11.jpg" alt /></Link></figure>
                    </li>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
