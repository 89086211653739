import React from 'react'

const HomeMission = () => {
    return (
        <>

            <div className="container">
                <div className="row">
                    <div className="col-lg-12 processfst_outsection">
                        <div className="row">
                            <div className="banner">
                                <h1 className="textabtn_Design">Our  Mission & Vision</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 prcsx_outsection">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="leftprcsx_leftosection">
                                    {/* <h2 className="procesheadng_text">Mission</h2> */}
                                    <img src="./assets/ply/Mission.JPG" alt="Awesome Logo" />

                                    <p className="prcoess_lnddtext">
                                        To build the best product. To be the first preference of customers in
                                        terms of quality, finish and durability. To prove to the market that quality will always be preferred over price.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="leftprcsx_leftosection">
                                    {/* <h2 className="procesheadng_text">Vision</h2> */}
                                    <img src="./assets/ply/Vision.png" alt="Awesome Logo" />
                                    <p className="prcoess_lnddtext">
                                        To provide a world-class lifestyle experience to the clients. To be on the top of the market in terms of technology, advanced machinery and continuous innovation and research.
                                        <br />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeMission