import React from 'react'

const OurJourney = () => {
    return (
        <>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 redback_papaeroutsection mobileback_guurntearea">
                        {/* <div class="backredback_papaeroutsection"> */}
                        <h1 class="svngrte_textdesign">Our Journey</h1>
                        <div className="nnewsylvangurnte_section">
                            {/* <img src="./assets/public/website/assets/images/g4.png" className="onlymobile_nametext" /> */}
                            <p className="ovetext_design1 wow fadeInDown" data-wow-delay="1000ms" data-wow-duration="1500ms">
                            </p><p className="ovetext_design1 wow fadeInDown animated" data-wow-delay="1000ms" data-wow-duration="1500ms"> ADARSH is derived from a Hindi word meaning Ideal or Perfect.
                                Maintaining the dignity of our name we are perfect with what we do.</p>
                            <p className="ovetext_design1 wow fadeInDown animated" data-wow-delay="1500ms" data-wow-duration="1500ms">The ADARSH group came into existence in the year 1982
                                under the aegis of Shri. Hanuman Prasad Gupta, a leader, a
                                visionary and a philanthropist. The group began its
                                operation by stepping into the furniture industry by the name
                                of ADARSH FURNITURE CENTRE. Adarsh Furniture Centre
                                was known as a local furniture shop and a furniture manufacturer.
                                We were the first furniture shop in the Satna district. We used to
                                manufacture furniture in the basement of the showroom at that time.  </p>
                            <p className="ovetext_design1 wow fadeInDown animated" data-wow-delay="1700ms" data-wow-duration="1500ms">With the increase in demand, we need to increase our production,
                                therefore in the year 1985, we shifted our workshop at the local
                                industrial area and started a new firm by the name of “Wood
                                Works”. This firm used to manufacturer a decent quantity of
                                products to meet our requirement and to supply many ongoing
                                projects at that time.  </p>
                            <p className="ovetext_design1 wow fadeInDown animated" data-wow-delay="1700ms" data-wow-duration="1500ms"> Adarsh Furniture Centre has also executed many tenders with
                                companies like L&T, LIC, Heidelberg cement, MP Birla Corp,
                                Reliance Cement, Our other two concerns “Adarsh Marketing” and “Adarsh
                                Distributors” had been known for the authorized dealerships of
                                Hindustan Unilever Ltd., Nestle and Nokia.  </p>
                            {/* <p className="ovetext_design1 wow fadeInDown animated" data-wow-delay="1700ms" data-wow-duration="1500ms"> Anticipating future increased market demand for Plywood,
                                Flush Door, and Block boards, the group diversified itself
                                and laid its manufacturing Ply, Doors and Boards and their
                                Allied products. That’s when ADARSH SALES PVT. LTD.
                                was incorporated in 2009 and being the game-changer in
                                the flush door and plywood industry. The company is committed to introducing innovative products to the Indian
                                Interior infrastructure industry. A perfect blend of
                                specialized prevalence joined with a wide scope of structure
                                spread over a rich palette settle on ADARSH items a perfect
                                decision.</p>
                            <p className="ovetext_design1 wow fadeInDown animated" data-wow-delay="1700ms" data-wow-duration="1500ms"> In February 2019, we started our new venture Adarsh Wood Craft
                                with a vision to manufacture and supply wooden furniture to the
                                Indian market and foreign market as well. Our firm consists of
                                experienced employees which are connected with us from the
                                beginning of our business, contributing a lot towards its growth. </p> */}
                            <h2 className="svngrte_textdesign1">"We Enhance Your Life With World-class Luxury"</h2>
                            <img src="./assets/public/website/assets/images/g3.png" className="onlymobile_plyimg" />
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>

        </>
    )
}

export default OurJourney