import React from 'react'
import SliderProcess from '../../component/slidercomponent/SliderProcess'

const Process = () => {
    return (
        <>
            <section className="clients-section ingallery_psection">
                <div className="auto-container">
                    <div className="banner">
                        <h1 className="textabtn_Design mt-lg-5">Our Process</h1>
                    </div>
                    <p className="aboutus_content">We Manufacture Products That Meet All Stringent Ecological
                        Standards. Each Sheet Experiences Thorough Quality Check Before
                        Being Sent To Market. All Things Considered, We Invest Heavily In
                        Offering Our Shoppers Unrivalled Quality Items, While Remembering
                        The “esteem For Cash” Remainders.</p>
                    <div className="inner-container">
                        <div className="sponsors-outer">
                            <ul className="sponsors-carousel owl-theme">
                                <SliderProcess />
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Process