import React, { useState } from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';
import Footer from '../../component/layout/Footer';
import emailjs from "@emailjs/browser";




const ContactUs = () => {

    const [name, setName] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");
    const [number, setNumber] = useState("");
    const [state, setState] = useState("");
    const [subject, setSubject] = useState("");
    const [city, setCity] = useState("");

    const nameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const stateChange = (e) => {
        const value = e.target.value;
        setState(value);
    };
    const cityChange = (e) => {
        const value = e.target.value;
        setCity(value);
    };
    const subjectChange = (e) => {
        const value = e.target.value;
        setSubject(value);
    };
    const numberChange = (e) => {
        const value = e.target.value;
        setNumber(value);
    };

    const mailChange = (e) => {
        const value = e.target.value;
        setmail(value);
    };
    const massagesChange = (e) => {
        const value = e.target.value;
        setMassages(value);
    };


    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(

                "service_oumku8k",
                "template_esy4tqm",
                "#myForm",
                "8i7i_APkS7sZ9CqPl"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.text === "OK") {
                        setName("");
                        setmail("");
                        setMassages("");
                        setNumber("");
                        setState("");
                        setSubject("");
                        setCity("");

                    }
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    };
    return (
        <>

            <div className=''>
                <section className="page-title">
                    <div className="content" style={{ backgroundImage: 'url(./assets/public/website/assets/images/background/contactus.jpg)' }}>
                        <div className="auto-container">
                            {/* <h1>Contact Us</h1> */}
                        </div>
                    </div>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">home</Link></li>
                        <li>Contact Us</li>
                    </ul>
                </section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 fstcontactmap_outsection">
                            <div className="banner">
                                <h1 className="textabtn_Design">
                                    Reach Us
                                </h1>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 releft_outsectionc">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="twovertical_section">
                                                <div className="sky-tabs sky-tabs-pos-top-left sky-tabs-anim-flip sky-tabs-response-to-icons">
                                                    <input type="radio" name="sky-tabs" defaultChecked id="sky-tab1" className="sky-tab-content-1" />
                                                    <label htmlFor="sky-tab1"><span><span><i className="fas fa-building bdicn_design" />Corporate
                                                        Office</span></span></label>
                                                    <input type="radio" name="sky-tabs" id="sky-tab2" className="sky-tab-content-2" />
                                                    <label htmlFor="sky-tab2"><span><span><i className="fas fa-industry bdicn_design" />Registered Office &amp;
                                                        Factory</span></span></label>
                                                    <label className="switcher"><span><span><a href="#"><i className="icon-reorder" />Tabs</a></span></span></label>
                                                    <ul>
                                                        <li className="sky-tab-content-1">
                                                            <div className="fxst_mapsection">
                                                                <div className="fsxtmap_iconarea">
                                                                    <i className="fas fa-map-pin uicon_mapdesign" />
                                                                </div>
                                                                <div className="fsxtmap_detailsarea">
                                                                    <p className="fsxtmap_dtlsnotedesign">
                                                                        Wood Work , In Front of Sitaram Petrol Pump, Rewa Road Satna (M.P.)
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="fxst_mapsection hbtnarea_structure">
                                                                <div className="fsxtmap_iconarea">
                                                                    <i className="fas fa-envelope uicon_mapdesign" />
                                                                </div>
                                                                <div className="fsxtmap_detailsarea">
                                                                    <p className="fsxtmap_dtlsnotedesign gapcxan_area">
                                                                        <a href="mailto:aspl2009@yahoo.in" className="chlnktxt_clrdesign">
                                                                            aspl2009@yahoo.in
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="fxst_mapsection hbtnarea_structure">
                                                                <div className="fsxtmap_iconarea">
                                                                    <i className="fas fa-phone-alt uicon_mapdesign" />
                                                                </div>
                                                                <div className="fsxtmap_detailsarea">
                                                                    <p className="fsxtmap_dtlsnotedesign gapcxan_area">
                                                                        <a href="tel:+91- 9977155591" className="chlnktxt_clrdesign">
                                                                            +91- 9977155591
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li className="sky-tab-content-2">
                                                            <div className="fxst_mapsection">
                                                                <div className="fsxtmap_iconarea">
                                                                    <i className="fas fa-map-pin uicon_mapdesign" />
                                                                </div>
                                                                <div className="fsxtmap_detailsarea">
                                                                    <p className="fsxtmap_dtlsnotedesign">
                                                                        Nh-75, Satna – Panna Road,
                                                                        In Front Of Bagri Petrol Pump,
                                                                        Vill. Hardua Majhol, P.o Sitpura,
                                                                        Satna (M.p), 485441
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="fxst_mapsection hbtnarea_structure">
                                                                <div className="fsxtmap_iconarea">
                                                                    <i className="fas fa-envelope uicon_mapdesign" />
                                                                </div>
                                                                <div className="fsxtmap_detailsarea">
                                                                    <p className="fsxtmap_dtlsnotedesign gapcxan_area">
                                                                        <a href="mailto:aspl2009@yahoo.in" className="chlnktxt_clrdesign">
                                                                            aspl2009@yahoo.in
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="fxst_mapsection hbtnarea_structure">
                                                                <div className="fsxtmap_iconarea">
                                                                    <i className="fas fa-phone-alt uicon_mapdesign" />
                                                                </div>
                                                                <div className="fsxtmap_detailsarea">
                                                                    <p className="fsxtmap_dtlsnotedesign gapcxan_area">
                                                                        <a href="tel:+91- 9977155591" className="chlnktxt_clrdesign">
                                                                            +91- 9977155591
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="frmdwen_section">
                                                <form method="POST" onSubmit={sendEmail} id="myForm">
                                                    <input type="hidden" name="_token" defaultValue="HPOBiUIzAHwmlDIOd3by6CtEQMlQhNBRocUiG7zb" />
                                                    <div className="row">
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="name" className="sanlabel_txdesign">Name*</label>
                                                                    <input type="text" className="form-control sanfld_lblarea" id="name"
                                                                        name="from_name"
                                                                        onChange={(e) => nameChange(e)}
                                                                        required
                                                                        value={name}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="phone" className="sanlabel_txdesign">Phone
                                                                        Number*</label>
                                                                    <input type="text" className="form-control sanfld_lblarea" id="phone"
                                                                        name='phone'
                                                                        onChange={(e) => numberChange(e)}
                                                                        value={number}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="email" className="sanlabel_txdesign">Email*</label>
                                                                    <input type="email" className="form-control sanfld_lblarea" id="email"
                                                                        name='email'
                                                                        onChange={(e) => mailChange(e)}
                                                                        value={mail}
                                                                        required

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="address" className="sanlabel_txdesign">Address*</label>
                                                                    <input type="text" className="form-control sanfld_lblarea" id="address"
                                                                        onChange={(e) => massagesChange(e)}
                                                                        name='massage'
                                                                        value={massages}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="state" className="sanlabel_txdesign">State*</label>
                                                                    <input type="text" className="form-control sanfld_lblarea" id="state"
                                                                        name="state"
                                                                        onChange={(e) => stateChange(e)}
                                                                        value={state}
                                                                        required
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-6">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="city" className="sanlabel_txdesign">City*</label>
                                                                    <input type="text" className="form-control sanfld_lblarea" id="city"
                                                                        name="city"
                                                                        onChange={(e) => cityChange(e)}
                                                                        value={city}
                                                                        required

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 col-12">
                                                            <div className="sanfld_area">
                                                                <div className="form-group sdfc_frmarea">
                                                                    <label htmlFor="subject" className="sanlabel_txdesign">Your
                                                                        Subject*</label>
                                                                    <textarea className="form-control sanfld_lblarea" id="subject" name="subject"
                                                                        onChange={(e) => subjectChange(e)}
                                                                        value={subject}
                                                                        required

                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a className="contctbtnarea_sloutr" href="">
                                                            <div className="contctbtnarea_sloutr">
                                                                <input type="submit" name className="mtnsbmt_design" />
                                                            </div>
                                                        </a>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 reright_outsectionc">
                                    <div className="row">
                                        <div className="col-lg-12 mt-lg-5">
                                            {/* <div className="smglxp_outsection">
                                                <div className="pin toltxip1">
                                                    <div className="toptiptxtds1">
                                                        <h2 className="areanametext">
                                                            KOLKATA OFFICE
                                                        </h2>
                                                        <div className="adress_boxarea">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-map-pin gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    ADVENTZ INFINITY@5, BLOCK - BN5, OFFICE NO - 802, SECTOR -
                                                                    V, SALTLAKE, KOLKATA - 700091
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-phone-alt gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    +91 33 2211-7418
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-fax gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    +91 33 2211-7419
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-envelope gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    reach@sylvanply.com
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-phone-alt gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    +91- 9073685001
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pin100 toltxip2">
                                                    <div className="toptiptxtds2">
                                                        <h2 className="areanametext">
                                                            LUCKNOW OFFICE
                                                        </h2>
                                                        <div className="adress_boxarea">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-map-pin gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    B-2/308 IN DLF MY PAD , VIBHUTI KHAND ,GOMTI NAGAR, LUCKNOW
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-phone-alt gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    +91-8171402737
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-envelope gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    reach@sylvanply.com
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pin101 toltxip3">
                                                    <div className="toptiptxtds3">
                                                        <h2 className="areanametext">
                                                            HYDERABAD OFFICE
                                                        </h2>
                                                        <div className="adress_boxarea">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-map-pin gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    Flat no. 403 on 4TH Floor Ramakrishna nivas apartment,
                                                                    S.R.Nagar ,Hyderabad -500038 Telengana
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-phone-alt gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    +91 9330903159
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-envelope gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    reach@sylvanply.com
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pin102 toltxip4">
                                                    <div className="toptiptxtds4">
                                                        <h2 className="areanametext">
                                                            BHUBANESWAR OFFICE
                                                        </h2>
                                                        <div className="adress_boxarea">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-map-pin gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    Plot No 2B , Jaydurga Nagar,2nd Floor, PS-
                                                                    Laxmisagar,Bhubaneswar-751006
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-phone-alt gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    +91 99330903166
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-envelope gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    reach@sylvanply.com
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pin103 toltxip5">
                                                    <div className="toptiptxtds5">
                                                        <h2 className="areanametext">
                                                            SILIGURI OFFICE
                                                        </h2>
                                                        <div className="adress_boxarea">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-map-pin gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    House No 111 N, Nazrul Sarani Hakimpara Siliguri PIN:734001
                                                                    First Floor
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-phone-alt gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    +91 9831365654
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="adress_boxarea excgap_design">
                                                            <div className="pointermap_areasection">
                                                                <div className="pointermap_areasection1">
                                                                    <i className="fas fa-envelope gpin_design" />
                                                                </div>
                                                                <div className="pointermap_areasection2">
                                                                    reach@sylvanply.com
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div style={{ overflow: 'hidden', resize: 'none', maxWidth: '100%', width: 500, height: 700 }}><div id="my-map-display" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=+Adarsh+Furniture+Centre,+Rewa+Road,+Satna+(M.p),&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="my-codefor-googlemap" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="grab-maps-authorization">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#my-map-display img{max-width:none!important;background:none!important;font-size: inherit;font-weight:inherit;}" }} /></div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default ContactUs