import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeAbout from './home/HomeAbout'
import ProductRange from './home/ProductRange'
import LatestBlogs from './home/LatestBlogs'
import EasyQuotation from './home/EasyQuotation'
import Testimonial from './home/Testimonial'
import MdMassage from './home/MdMassage'
import HomeMission from './home/HomeMission'
import OurJourney from './home/OurJourney'
import Process from './home/Process'
import OurClients from './home/OurClients'


const Home = () => {
  return (
    <>
      <HomeBanner />
      <OurJourney />
      {/* <MdMassage /> */}
      <HomeAbout />
      <HomeMission />
      <Process />
      <EasyQuotation />
      <Testimonial />
      <ProductRange />
      <LatestBlogs />
      <OurClients />

    </>
  )
}

export default Home