import React from 'react'
import { Link } from "react-router-dom";


const SupremePly = () => {
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 aboutus_outsection">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="leftimg_outsection mt-lg-5">
                                        <img src="./assets/ply/SUPREME PLY.jpg" className="abtus_imgdesign" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="righttime_outsection">
                                        <h1 className="productzx_nmetextdesign">
                                            SUPREME PLY (IS:303) BWP
                                        </h1>
                                        <p className="aboutus_content">
                                        </p>
                                        <p className="aboutus_content">SUPREME Grade Plywood is high-quality Water Proof plywood. It is constructed from a
                                            red core (hardcore) with a soft core in the centre to increase its nail and screw holding
                                            capacity. It is constructed with PF resin which allows it to develop its waterproof
                                            properties. It is also constructed according to IS:303 norms. It is popular in the market for
                                            its strength and waterproofing. </p>



                                    </div>
                                </div>

                                <div className="col-lg-12 kftrs_outsection">
                                    <h1 className="rtrti_text">
                                        Unique features:
                                    </h1>
                                    <p className="sizethckns_design">
                                    </p>


                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="">

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Boiling waterproof  </p>
                                                            <p>➤ No warping or bending</p>
                                                            <p>➤ Fungal, termite and borer treated </p>
                                                            <p>➤ Superior Stability and durability </p>
                                                            <p>➤ Dimensional accuracy </p>
                                                            <p>➤ High stability strength </p>



                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ 15 layered ply for 19 mm (Additional 2 layers for other
                                                                thickness) </p>
                                                            <p>➤ 13 layered ply for 16 mm
                                                                (Additional 2 layers for other
                                                                thickness)  </p>
                                                            <p>➤ 11 layered ply for 12 mm
                                                                (Additional 2 layers for other
                                                                thickness) </p>
                                                            <p>➤ High shock and buckling
                                                                resistance </p>
                                                            <p>➤ Chemically treated and seasoned
                                                                as per BIS </p>
                                                            <p>➤ Zero defects in flexure and edge
                                                                loading </p>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 className="rtrti_text1">
                                        Specifications
                                    </h1>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="">

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Thickness available : 19mm, 12mm, 9mm &6mm</p>
                                                            <p>➤ Size available  : Available in all standard sizes</p>
                                                            <p>➤ Face  : Gurjan (with dipping)
                                                            </p>
                                                            <p>➤Core  : All red hard core </p>
                                                            <p>➤Adhesive for bonding  : PF resin as per BIS standards </p>
                                                            <p>➤ Density  : Above 750 kg/cm3</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="">


                                                            <p>➤ Moisture content :  8-12%
                                                            </p>
                                                            <p>➤Glue adhesion strength  : Excellent </p>
                                                            <p>➤ Resistance to misuse :  Excellent </p>
                                                            <p>➤ Water-resistance test  : Confirms to IS:303</p>
                                                            <p>➤ Warranty  : Any manufacturing defects as per
                                                                warranty certificate.</p>
                                                            <p>➤ IS STANDARDS : IS:303</p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h1 className="rtrti_text2">
                                        END USAGE:
                                    </h1>
                                    <ul className="spcgh_area">
                                        <li>
                                            <img src="../assets/public/storage/productends/January2024/HcNGY47FiqsJV3LAA7Ma.png" alt="Commercial Doors" />
                                            <span>FURNITURE, PARTITIONS, KITCHENS, RACKS, SHELVES ETC.</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 aboutus_outsection">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="leftimg_outsection">
                                        <img src="./assets/ply/ECO-XL PLY.jpg" className="abtus_imgdesign" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="righttime_outsection">
                                        <br />
                                        <br />
                                        <h1 className="productzx_nmetextdesign">
                                            ECO-XL PLY: (IS:303) MR
                                        </h1>
                                        <p className="aboutus_content">
                                        </p>
                                        <p className="aboutus_content">With phenomenal highlights like high life span and mechanical strength, ASPL
                                            invests heavily in its ECO-XL Ply. This exceptionally strong pressed wood is
                                            an all-around preferred item because of numerous great credits like unrivalled
                                            screw holding limit, nail holding strength, uniform thickness, and significantly
                                            more. Aside from independence from distorting, it is water-safe It is constructed
                                            according to the IS:303 norms.
                                            . </p>



                                    </div>
                                </div>

                                <div className="col-lg-12 kftrs_outsection">
                                    <h1 className="rtrti_text">
                                        Unique features:
                                    </h1>
                                    <p className="sizethckns_design">
                                    </p>


                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="">

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Boiling water resistance  </p>
                                                            <p>➤  No warping or bending </p>
                                                            <p>➤ Fungal, termite and borer treated  </p>
                                                            <p>➤
                                                                Superior Stability and durability </p>
                                                            <p>➤ Dimensional accuracy  </p>
                                                            <p>➤  High stability strength </p>

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ 15 layered ply for 19 mm (Additional 2
                                                                layers for other thickness)  </p>
                                                            <p>➤  13 layered ply for 16 mm (Additional 2
                                                                layers for other thickness) </p>
                                                            <p>➤11 layered ply for 12 mm (Additional 2
                                                                layers for other thickness)
                                                            </p>
                                                            <p>➤  High shock and buckling resistance </p>
                                                            <p>➤  Chemically treated and seasoned as
                                                                per BIS </p>
                                                            <p>➤ Zero defects in flexure and edge
                                                                loading  </p>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 className="rtrti_text1">
                                        Specifications
                                    </h1>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="">

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Thickness available :  19mm, 12mm, 9mm &6mm</p>
                                                            <p>➤ Size available  : Available in all standard sizes</p>
                                                            <p>➤ Face  : Gurjan (with dipping)</p>
                                                            <p>➤ Core :  Alternate core</p>
                                                            <p>➤ Adhesive for bonding  : MUF resin as per BIS standards.</p>
                                                            <p>➤ Density  : Above 650 kg/cm3
                                                            </p>

                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Moisture content :  8-12% </p>
                                                            <p>➤ Glue adhesion strength  : Excellent</p>
                                                            <p>➤ Resistance to misuse  : Excellent</p>
                                                            <p>➤ Water-resistance test :  Confirms to IS:303</p>
                                                            <p>➤ Warranty  : Any manufacturing defects as per
                                                                warranty certificate.</p>
                                                            <p>➤ IS STANDARDS  : IS:303
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h1 className="rtrti_text2">
                                        END USAGE:
                                    </h1>
                                    <ul className="spcgh_area">
                                        <li>
                                            <img src="../assets/public/storage/productends/January2024/HcNGY47FiqsJV3LAA7Ma.png" alt="Commercial Doors" />
                                            <span>FURNITURE, PARTITIONS, RACKS, SHELVES ETC.</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 aboutus_outsection">
                            <div className="row">
                                <div className="col-lg-5">
                                    <div className="leftimg_outsection">
                                        <img src="./assets/ply/ECO-XL PLY.jpg" className="abtus_imgdesign" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="righttime_outsection">
                                        <br />
                                        <br />
                                        <h1 className="productzx_nmetextdesign">
                                            SILVER PLY(IS;303) MR
                                        </h1>
                                        <p className="aboutus_content">
                                        </p>
                                        <p className="aboutus_content">SILVER Grade plywood is MR grade Commercial plywood with an alternative core
                                            according to the IS: 303 norms. It is constructed from an alternate core (hard and
                                            softcore) with MUF resin. This profoundly sturdy pressed wood is an all-around
                                            preferred item because of numerous grand credits like unrivalled screw holding
                                            limit, nail holding strength, uniform thickness, and considerably more. Aside from
                                            independence from twisting, it is likewise impervious to termites and drills
                                            . </p>



                                    </div>
                                </div>

                                <div className="col-lg-12 kftrs_outsection">
                                    <h1 className="rtrti_text">
                                        Unique features:
                                    </h1>
                                    <p className="sizethckns_design">
                                    </p>


                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="">

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Boiling water resistance   </p>
                                                            <p>➤  No warping or bending  </p>
                                                            <p>➤   Fungal, termite and borer treated </p>
                                                            <p>➤   Superior Stability and durability </p>



                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Dimensional accuracy   </p>
                                                            <p>➤   High stability strength
                                                            </p>
                                                            <p>➤ High shock and buckling
                                                                resistance   </p>
                                                            <p>➤ Chemically treated and seasoned
                                                                as per BIS   </p>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <h1 className="rtrti_text1">
                                        Specifications
                                    </h1>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="">

                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Thickness available  : 18mm, 12mm, 9mm &6mm </p>
                                                            <p>➤ Size  : available Available in all standard sizes</p>
                                                            <p>➤ Face  : Gurjan (with dipping)
                                                            </p>
                                                            <p>➤Core  : Alternate core
                                                            </p>
                                                            <p>➤  Adhesive for bonding MUF resin as per :  BIS standards.</p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="">
                                                            <p>➤ Density  : Above 500 kg/cm3
                                                            </p>
                                                            <p>➤ Moisture content :  8-12% </p>
                                                            <p>➤ Water-resistance test  : Confirms to IS:303 </p>
                                                            <p>➤ Warranty :  Any manufacturing defects as per
                                                                : warranty certificate. </p>
                                                            <p>➤ IS STANDARDS : IS:303 </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h1 className="rtrti_text2">
                                        END USAGE:
                                    </h1>
                                    <ul className="spcgh_area">
                                        <li>
                                            <img src="../assets/public/storage/productends/January2024/HcNGY47FiqsJV3LAA7Ma.png" alt="Commercial Doors" />
                                            <span>RACKS, SHELVES, SPEAKER CABINETS ETC</span>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </section>
        </>
    )
}

export default SupremePly