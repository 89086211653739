import React from 'react'
import SliderOurClients from '../../component/SliderOurClients'

const OurClients = () => {
    return (
        <>

            <section className="clients-section outnews_section">
                <div className="auto-container">
                    <div className="banner">
                        <h1 className="textabtn_Design">OUR CLIENTS</h1>
                    </div>
                    <div className="inner-container mt-lg-5">
                        <div className="sponsors-outer">
                            <ul className="sponsors-carousel owl-theme">
                               <SliderOurClients />
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default OurClients