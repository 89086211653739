import React from 'react'
import { Link } from "react-router-dom";
import FlushDoorPremium from './FlushDoorPremium';


const ShutteringPly = () => {
    return (
        <>
            <section className="page-title">
                <div className="content" style={{ backgroundImage: 'url("./assets/public/website/assets/images/background/products.jpg")' }}>
                </div>
                <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                    <li>Product Details</li>
                </ul>
            </section>

            <div className="container">
                <div className="row">
                    <div className="col-lg-12 prodcdtls_outsection">
                        <div className="sky-tabs sky-tabs-pos-top-left sky-tabs-anim-flip sky-tabs-response-to-icons">
                            <input type="radio" name="sky-tabs" defaultChecked id="sky-tab1" className="sky-tab-content-1" />
                            <label className="switcher"><span><span><a to="#"><i className="icon-reorder" />Tabs</a></span></span></label>
                            <ul>
                                <li className="sky-tab-content-1">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="productdtls_sfvoutsection">
                                                    <div className="product-imgs">
                                                        <div className="img-display">
                                                            <div className="img-showcase">
                                                                <img src="../assets/ply/Weight.jpg" alt="shoe image" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="productdtls_trgsection">
                                                    <h1 className="productzx_nmetextdesign">
                                                        SHUTTERING PLY(IS:4990) PF RESIN
                                                    </h1>
                                                    <p className="lzpum_textdesign">
                                                        ASPL shuttering ply is BWP grade plywood, treated with preservatives.
                                                        Manufactured with selected hardwood, this ply withstands the load and
                                                        forces encountered during the pouring of concrete and vibration. It
                                                        renders a better economy for its repeated usage compared to other
                                                        plywood varieties.
                                                    </p>


                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 kftrs_outsection">
                                                <h1 className="rtrti_text">
                                                    Unique Features:
                                                </h1>
                                                <p className="sizethckns_design">
                                                </p>


                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="specitwo_outarea">

                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="specitwo_outarea">
                                                                        <p>➤ Boiling water resistance   </p>
                                                                        <p>➤ More repetition than ordinary
                                                                            plywood
                                                                        </p>


                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div className="specitwo_outarea">
                                                                        <p>➤ Tough bonding </p>
                                                                        <p>➤ Termite resistance </p>
                                                                        <p>➤ Chemically treated and
                                                                            seasoned as per BIS
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h1 className="rtrti_text1">
                                                    Specifications
                                                </h1>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="specitwo_outarea">

                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="specitwo_outarea">
                                                                        <p>➤ Thickness available  : 12mm </p>
                                                                        <p>➤ Size available :  8*4
                                                                        </p>
                                                                        <p>➤ Adhesive for bonding  : PF resin as per BIS standards.</p>
                                                                        <p>➤ Density  : Above 700 kg/cm3</p>




                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div className="specitwo_outarea">

                                                                        <p>➤ Weight  : 30kg</p>
                                                                        <p>➤ Water-resistance test  : Confirms to  IS:4990</p>
                                                                        <p>➤Warranty  : Any manufacturing defects as per
                                                                            warranty certificate. </p>
                                                                        <p>➤IS STANDARDS : IS:4990
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h1 className="rtrti_text2">
                                                    End Usage:
                                                </h1>
                                                <ul className="spcgh_area">
                                                    <li>
                                                        <img src="../assets/public/storage/productends/January2024/HcNGY47FiqsJV3LAA7Ma.png" alt="Commercial Doors" />
                                                        <span>CONSTRUCTION AND CIVIL WORKS.</span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>


                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ShutteringPly