import React from 'react'
import { Link } from "react-router-dom";
import LatestBlogs from '../home/LatestBlogs';



const Blog = () => {
    return (
        <>

            <div className=''>
                <section className="page-title" >
                    <div className="content" style={{ backgroundImage: 'url(./assets/public/website/assets/images/background/blogs.jpg)' }}>
                    </div>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">home</Link></li>
                        <li>Our Blog</li>
                    </ul>
                </section>
            </div>
            {/* <LatestBlogs /> */}

            <section className="">
                <div className="auto-container">

                    <div className="banner">
                        <h1 className="textabtn_Design">Latest Blogs</h1>
                    </div>
                    <div className="row clearfix">

                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_1">
                                        <img src="./assets/public/storage/blogs/August2024/TvFc9zVfzp2dlXlZIJiA.jpeg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">20-08-2024</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_1">Unleashing Creativity: Adarsh Sales Pvt. Ltd Guide to Unique Home and Office Design</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_2">
                                        <img src="./assets/public/storage/blogs/August2024/executive.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">24-08-2024</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_2">Adarsh Sales Pvt. Ltd Manufacturing Process: A Journey of Craftsmanship</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_3">
                                        <img src="./assets/public/storage/blogs/August2024/flexible plywood 1.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">26-08-2024</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_3">Adarsh Sales Pvt. Ltd Robusta Premium Plus: The Pinnacle of Plywood Performance</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="">
                <div className="auto-container">
                    <div className="row clearfix">
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_1">
                                        <img src="./assets/public/storage/blogs/June2024/LDgvokzuhdKvYOEzpCoY.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-06-30</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_1">Plywood Furniture Care Guide: Expert Tips from Adarsh Sales Pvt. Ltd</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_2">
                                        <img src="./assets/public/storage/blogs/June2024/1P0w5FhRpgMIBWstn9bb.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-06-26</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_2">Which brand of plywood
                                        is best?</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_3">
                                        <img src="./assets/public/storage/blogs/May2024/GeaXwyLBHJYGUUZ1AWbw.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-23</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_3">Luxury Bedroom Interior Design in India - Adarsh Sales Pvt. Ltd</Link></h4>
                                </div>
                            </div>
                        </div>


                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_1">
                                        <img src="./assets/public/storage/blogs/May2024/OtvoPnoReIqkVYl0dXD3.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-03-29</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_1">Which ply is
                                        best for home?</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_1">
                                        <img src="./assets/public/storage/blogs/May2024/jutRVNyDb9Zr4w3LRW6Q.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-03-26</div>
                                    <h4 className="content_headingtext"><Link to="blog_details/plywood-strongest.html">What
                                        plywood is strongest?</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_2">
                                        <img src="./assets/public/storage/blogs/May2024/0GtW0mI58NxUe1eCwkRH.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-21</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_2">Marine vs.
                                        Exterior Plywood: Which is Best for Your Project?</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_3">
                                        <img src="./assets/public/storage/blogs/May2024/mdKq52XHQeNR87yoJI6q.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-21</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_3">What Type of
                                        Plywood is Best For Outdoor Use in India ?</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_1">
                                        <img src="./assets/public/storage/blogs/May2024/G8mrV3VHBi1aiqKljtWZ.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-12</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_1">Best Plywood in India -
                                        Adarsh Sales Pvt. Ltd</Link></h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog_details_2">
                                        <img src="./assets/public/storage/blogs/May2024/qqBTjWFYvY0enboumFa9.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">2024-04-05</div>
                                    <h4 className="content_headingtext"><Link to="/blog_details_2">The
                                        Ultimate Guide to Choosing the Best Plywood for Your Wardrobe: A  Adarsh Sales Pvt. Ltd
                                        Perspective</Link></h4>
                                </div>
                            </div>
                        </div>






                    </div>
                </div>
            </section>


        </>
    )
}

export default Blog