import React from 'react'
import { Link } from "react-router-dom";


const HomeAbout = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 aboutus_outsection">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="leftimg_outsection mt-lg-5">
                                    <img src="./assets/public/website/assets/images/resource/about_1.png" className="abtus_imgdesign" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="righttime_outsection">
                                    <div className="banner">

                                        <h1 className="textabtn_Design aus_hallttext">About Us</h1>
                                    </div>
                                    <p className="aboutus_content">
                                    </p>
                                    <p className="aboutus_content">We ADARSH SALES PVT. LTD. Is One Of The Strongest Manufacturer And Supplier Of PLYWOOD, DOORS & BLOCK-BOARDS In The Indian Domestic Market. With The Intention Of Quality First ADARSH SALES PVT. LTD. Is Proudly Serving It’s Costumer Since 2009. Our Plant Is Equipped With State-of-the-art Equipment And Testing Facilities Making Sure The Quality Of Product During Every Process Of Manufacturing, To Produce A Product According To International Standards. Our In-house Production Of Resin Helps In Stronger Bonding And Increase The Durability Of Product, Making The Product Waterproof. Our  R&D Ensures Constant Improvement Of Existing Product And Innovation Of New Ones To Offer Better, Offer More To Our Costumer.</p>
                                    <p className="aboutus_content">  Moreover, Our Process Is Eco-friendly And Energy Efficient With An In-house Seasoning Plant, Measurers That Ensures The Health And Safety Of Our Manpower. Each Batch That Bears The ADARSH Name Is Checked As Per BIS Norms And EUROPEAN STANDARDS Before Being Released In The Market.</p>
                                    <div className="fact-counter style-three factposition_area">
                                        <div className="row clearfix">

                                            <div className="column counter-column col-lg-6 col-md-6 col-sm-12 abtfst_gaparea">
                                                <div className="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                                    <div className="content">
                                                        <div className="habtus_section">
                                                            <img src="./assets/public/website/assets/images/resource/workplace.gif" className="jhbtus_imgdesign" />
                                                        </div>
                                                        <div className="count-outer count-box alternate">
                                                            <span className="count-text" data-speed={5000} data-stop={2854}>15</span>+
                                                        </div>
                                                        <h4 className="counter-title cmpltd_prjctstextdesign">
                                                        Experience year</h4>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="column counter-column col-lg-6 col-md-6 col-sm-12">
                                                <div className="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                                    <div className="content">
                                                        <div className="habtus_section">
                                                            <img src="./assets/public/website/assets/images/resource/reactions.gif" className="jhbtus_imgdesign" />
                                                        </div>
                                                        <div className="count-outer count-box">
                                                            <span className="count-text" data-speed={3000} data-stop={100}>90</span>+
                                                        </div>
                                                        <h4 className="counter-title cmpltd_prjctstextdesign">
                                                        Completed Project</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="kmrebtn_area">
                                        <Link to="/overview" className="theme-btn btn-style-two"><span className="txt">Know More</span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeAbout