import React from 'react'
import { Link } from "react-router-dom";


const EasyQuotation = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 newctta_outsection">
                        <div className="row">
                            <div className="col-lg-6 newctaleft_outsection">
                                <div className="ctfst">
                                    <h1 className="cfxht_text">
                                        Get An Easy Quotation
                                    </h1>
                                    <div className="button-box btnbox_area">
                                        <Link className="btn-style-four theme-btn" to="/contact-us" data-toggle="modal" data-target="#myModal"><span className="txt" style={{ color: "white" }}>Click Now</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 newctaright_outsection">
                                <div className="slice_phnbox">
                                    <span className="iconcf flaticon-call-2" />
                                    Call Us Any Time:<br />
                                    <a to="tel:+91 9977155591">+91 9977155591</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EasyQuotation