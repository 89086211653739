import React from 'react'
import SliderHomeBanner from '../../component/slidercomponent/SliderHomeBanner'

const HomeBanner = () => {
    return (
        <>
            <div id="demo" className="carousel slide sylvanply_sliderarea" data-ride="carousel">
                {/* <ul className="carousel-indicators">
                    <li data-target="#demo" data-slide-to={0} className="active" />
                    <li data-target="#demo" data-slide-to={1} />
                    <li data-target="#demo" data-slide-to={2} />
                    <li data-target="#demo" data-slide-to={3} />
                    <li data-target="#demo" data-slide-to={4} />
                    <li data-target="#demo" data-slide-to={5} />
                    <li data-target="#demo" data-slide-to={6} />

                </ul> */}

                <div className="carousel-inner">

                    <SliderHomeBanner />

                    {/* <div className="carousel-item active">
                        <img src="./assets/public/storage/sliders/July2024/IrYgYNAusCqRjh5tU3Lg.jpeg" alt="sylvanply-banne1" />
                    </div>
                    <div className="carousel-item ">
                        <img src="./assets/public/storage/sliders/July2024/DfdGS50XQkEGjC7BMKbV.jpg" alt="sylvanply-banne1" />
                    </div>
                    <div className="carousel-item ">
                        <img src="./assets/public/storage/sliders/July2024/qA8KDC5b8IWWX5vJhQWO.jpg" alt="sylvanply-banne1" />
                    </div>
                    <div className="carousel-item ">
                        <img src="./assets/public/storage/sliders/July2024/Xfos0KcZ3tDjxhrumrLS.jpg" alt="sylvanply-banne1" />
                    </div>
                    <div className="carousel-item ">
                        <img src="./assets/public/storage/sliders/July2024/wZIOCHm95QofkFcuXGWw.jpg" alt="sylvanply-banne1" />
                    </div>
                    <div className="carousel-item ">
                        <img src="./assets/public/storage/sliders/July2024/olk1JId48BcVJJMNtuGv.jpg" alt="sylvanply-banne1" />
                    </div>
                    <div className="carousel-item ">
                        <img src="./assets/public/storage/sliders/July2024/FcxIHHszecRCn2HBUeUD.jpg" alt="sylvanply-banne1" />
                    </div> */}
                </div>
                <a className="carousel-control-prev" href="#demo" data-slide="prev">
                    <span className="carousel-control-prev-icon" />
                </a>
                <a className="carousel-control-next" href="#demo" data-slide="next">
                    <span className="carousel-control-next-icon" />
                </a>
            </div>
        </>
    )
}

export default HomeBanner