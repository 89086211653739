import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';


import { Autoplay } from 'swiper/modules';

export default function SliderHomeBanner() {
    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}


                modules={[Autoplay]}
                className="mySwiper"
            >

                {/* <SwiperSlide>
                    <div className="swiper-slide ">
                        <div className="hero__slider--items hero__slider--bg slider3">
                            <div className="container-fluid">
                                <div className="hero__slider--items__inner">
                                    <div className="row row-cols-1">
                                        <div className="col">
                                            <div className="slider__content">
                                                <p className="slider__content--desc desc1 text-white mb-15">Welcome to Adarsh Sales Pvt. Ltd.</p>
                                                <h2 className="slider__content--maintitle text-white h1">We Create Awesome <br /> Plywood  
                                                    </h2>
                                                <p className="slider__content--desc text-white mb-35 d-sm-2-none">We are Goldwood! We love to decorate We are happy when you are!</p>
                                                <a className="slider__content--btn primary__btn" href="shop.html">Start to Buying</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide ">
                        <div className="hero__slider--items hero__slider--bg slider1">
                            <div className="container-fluid">
                                <div className="hero__slider--items__inner">
                                    <div className="row row-cols-1">
                                        <div className="col">
                                            <div className="slider__content">
                                                <p className="slider__content--desc desc1 text-white mb-15"> Welcome to Adarsh Sales Pvt. Ltd. </p>
                                                <h2 className="slider__content--maintitle text-white h1">Elevate your Space <br />
                                                    Bring Beauti</h2>
                                                <p className="slider__content--desc text-white mb-35 d-sm-2-none">Dive into a world of laminates and choose from luxurious colors, intricate <br /> patterns, and captivating textures to craft a truly mesmerizing interior design.</p>
                                                <a className="slider__content--btn primary__btn" href="shop.html">Start to Buying</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide> */}


                <SwiperSlide>
                    <div className="">
                        <img src="./assets/ply/Banner_001.jpg" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
               
                <SwiperSlide>
                    <div className=" ">
                        <img src="./assets/ply/Banner_03.png" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" ">
                        <img src="./assets/ply/Banner_04.png" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" ">
                        <img src="./assets/ply/Banner_05.png" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" ">
                        <img src="./assets/ply/Banner_02.jpg" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className=" ">
                        <img src="./assets/ply/Banner_03.jpg" alt="sylvanply-banne1" />
                    </div>
                </SwiperSlide>
                

            </Swiper>
        </>
    );
}
