import React, { useState } from 'react'
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";


const Careers = () => {

    const [name, setName] = useState("");
    const [mail, setmail] = useState("");
    const [massages, setMassages] = useState("");
    const [number, setNumber] = useState("");
    const [state, setState] = useState("");
    const [subject, setSubject] = useState("");
    const [city, setCity] = useState("");

    const nameChange = (e) => {
        const value = e.target.value;
        setName(value);
    };
    const stateChange = (e) => {
        const value = e.target.value;
        setState(value);
    };
    const cityChange = (e) => {
        const value = e.target.value;
        setCity(value);
    };
    const subjectChange = (e) => {
        const value = e.target.value;
        setSubject(value);
    };
    const numberChange = (e) => {
        const value = e.target.value;
        setNumber(value);
    };

    const mailChange = (e) => {
        const value = e.target.value;
        setmail(value);
    };
    const massagesChange = (e) => {
        const value = e.target.value;
        setMassages(value);
    };


    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(

                "service_oumku8k",
                "template_esy4tqm",
                "#myForm",
                "8i7i_APkS7sZ9CqPl"
            )
            .then(
                function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    if (response.text === "OK") {
                        setName("");
                        setmail("");
                        setMassages("");
                        setNumber("");
                        setState("");
                        setSubject("");
                        setCity("");

                    }
                },
                function (error) {
                    console.log("FAILED...", error);
                }
            );
    };
    return (
        <>
            <div>
                <section className="page-title">
                    <div className="content" style={{ backgroundImage: 'url("./assets/public/website/assets/images/background/Careers.jpg")' }}>
                    </div>
                    <ul className="page-breadcrumb">
                        <li><Link to="/">home</Link></li>
                        <li>Careers</li>
                    </ul>
                </section>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 warenty_loutsection2">
                            <div className="waefnty_outsectionjs2">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8 offset-lg-2 wrenty_outsection">
                                            <h2 className="fitrdtext_design rgdsp_section">
                                                Fill in your details and we’ll call you back.
                                            </h2>
                                            {/* <form method="post" action="https://sylvanply.com/become-influencer">
                                                <input type="hidden" name="_token" defaultValue="HPOBiUIzAHwmlDIOd3by6CtEQMlQhNBRocUiG7zb" />
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="usr" className="sanlabel_txdesign">Name</label>
                                                                <span className="trsc_design">
                                                                    *
                                                                </span>
                                                                <input type="text" className="form-control sanfld_lblarea" id="usr" name="name" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="usr" className="sanlabel_txdesign">Phone Number</label>
                                                                <span className="trsc_design">
                                                                    *
                                                                </span>
                                                                <input type="tel" maxLength={10} className="form-control sanfld_lblarea" id="usr" name="phoneno" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="usr" className="sanlabel_txdesign">Email Id</label>
                                                                <input type="email" className="form-control sanfld_lblarea" id="usr" name="emailid" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="usr" className="sanlabel_txdesign">City</label>
                                                                <span className="trsc_design">
                                                                    *
                                                                </span>
                                                                <input type="text" className="form-control sanfld_lblarea" id="usr" name="city" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="usr" className="sanlabel_txdesign">Address</label>
                                                                <span className="trsc_design">
                                                                    *
                                                                </span>
                                                                <input type="text" className="form-control sanfld_lblarea1" id="usr" name="address" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sbmnt_area">
                                                        <Link to="">
                                                            <input type="submit" name className="sbnmtns_designkn" defaultValue="Submit" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </form> */}

                                            <form method="POST" onSubmit={sendEmail} id="myForm">
                                                <input type="hidden" name="_token" defaultValue="HPOBiUIzAHwmlDIOd3by6CtEQMlQhNBRocUiG7zb" />
                                                <div className="row">
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="name" className="sanlabel_txdesign">Name*</label>
                                                                <input type="text" className="form-control sanfld_lblarea" id="name"
                                                                    name="from_name"
                                                                    onChange={(e) => nameChange(e)}
                                                                    required
                                                                    value={name}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="phone" className="sanlabel_txdesign">Phone
                                                                    Number*</label>
                                                                <input type="text" className="form-control sanfld_lblarea" id="phone"
                                                                    name='phone'
                                                                    onChange={(e) => numberChange(e)}
                                                                    value={number}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="email" className="sanlabel_txdesign">Email*</label>
                                                                <input type="email" className="form-control sanfld_lblarea" id="email"
                                                                    name='email'
                                                                    onChange={(e) => mailChange(e)}
                                                                    value={mail}
                                                                    required

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="address" className="sanlabel_txdesign">Address*</label>
                                                                <input type="text" className="form-control sanfld_lblarea" id="address"
                                                                    onChange={(e) => massagesChange(e)}
                                                                    name='massage'
                                                                    value={massages}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="state" className="sanlabel_txdesign">State*</label>
                                                                <input type="text" className="form-control sanfld_lblarea" id="state"
                                                                    name="state"
                                                                    onChange={(e) => stateChange(e)}
                                                                    value={state}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-6">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="city" className="sanlabel_txdesign">City*</label>
                                                                <input type="text" className="form-control sanfld_lblarea" id="city"
                                                                    name="city"
                                                                    onChange={(e) => cityChange(e)}
                                                                    value={city}
                                                                    required

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12 col-12">
                                                        <div className="sanfld_area">
                                                            <div className="form-group sdfc_frmarea">
                                                                <label htmlFor="subject" className="sanlabel_txdesign">Your
                                                                    Subject*</label>
                                                                <textarea className="form-control sanfld_lblarea" id="subject" name="subject"
                                                                    onChange={(e) => subjectChange(e)}
                                                                    value={subject}
                                                                    required

                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <a className="contctbtnarea_sloutr" href="">
                                                        <div className="contctbtnarea_sloutr">
                                                            <input type="submit" name className="mtnsbmt_design" />
                                                        </div>
                                                    </a>
                                                </div>
                                            </form>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    )
}

export default Careers