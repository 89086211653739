import React from 'react'
import { Link } from "react-router-dom";
import SliderProduct from '../../component/slidercomponent/SliderProduct';


const ProductRange = () => {
    return (
        <>

            <section className="services-section-four product_mainarea">
                <div className="auto-container">
                    <div className="banner">
                        <h1 className="textabtn_Design">Product Range</h1>
                    </div>
                    <div className="row">
                        <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/plywood"><img src="./assets/public/website/assets/images/resource/product-range1.jpg" alt /></Link>
                                </div>
                                <div className="lower-content">
                                    <div className="content">

                                        <h5><Link to="/plywood">Plywood</Link></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/block-boards"><img src="./assets/public/website/assets/images/resource/product-range2.jpg" alt /></Link>
                                </div>
                                <div className="lower-content">
                                    <div className="content">

                                        <h5><Link to="/block-boards">Blockboards</Link></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="service-block-three col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/flush-doors"><img src="./assets/public/website/assets/images/resource/product-range33.jpg" alt /></Link>
                                </div>
                                <div className="lower-content">
                                    <div className="content">

                                        <h5><Link to="/flush-doors">Flush Door</Link></h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="productrnge_btnarea">
                            <div className="kmrebtn_area">
                                <Link to="/plywood" className="theme-btn btn-style-two"><span className="txt"> Browse More</span></Link>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>


            <section className="clients-section ingallery_psection">
                <div className="auto-container">
                <div className="banner">
                        <h1 className="textabtn_Design">Our Product</h1>
                    </div>
                    <div className="inner-container">
                        <div className="sponsors-outer">
                            <ul className="sponsors-carousel owl-theme">
                                <SliderProduct />
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ProductRange