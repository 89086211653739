import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import Abcd from "./pages/home/Abcd";
import ContactUs from "./pages/contact-us/ContactUs";
import AboutUs from "./pages/about/AboutUs";
import Blog from "./pages/blog/Blog";
import FlushDoors from "./pages/product/FlushDoors";
import Plywood from "./pages/product/Plywood";
import BlockBoards from "./pages/product/BlockBoards";
import Gallery from "./pages/gallery/Gallery";
import Careers from "./pages/careers/Careers";
import Overview from "./pages/about/Overview";
import BlogDetails_3 from "./pages/blog_details/BlogDetails_3";
import BlogDetails_1 from "./pages/blog_details/BlogDetails_1";
import BlogDetails_2 from "./pages/blog_details/BlogDetails_2";
import ShutteringPly from "./pages/product/ShutteringPly";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/contact-us" element={<ContactUs />} />
            {/* <Route path="/about-us" element={<AboutUs />} /> */}
            <Route path="/overview" element={<Overview />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/flush-doors" element={<FlushDoors />} />
            <Route path="/shuttering-ply" element={<ShutteringPly />} />
            <Route path="/plywood" element={<Plywood />} />
            <Route path="/block-boards" element={<BlockBoards />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/blog_details_3" element={<BlogDetails_3 />} />
            <Route path="/blog_details_1" element={<BlogDetails_1 />} />
            <Route path="/blog_details_2" element={<BlogDetails_2 />} />
           
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
