import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderTestimonial() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
               
                autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 1,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <div className="testimonial-block">
                        <div className="inner-box">
                            <div className="author-image">
                                <img src="./assets/public/storage/testimonials/December2023/profile.png" alt />
                            </div>
                            <div className="text">“While using a product we look forward to longevity, quality, after-sales service, and price. Adarsh Sales Pvt. Ltd gateway flush doors and plywoods meet all the Standards.”</div>
                            <h5>Bijay Jain</h5>
                            <div className="designation">Arrjavv Builder Private Limited</div>
                        </div>
                    </div>
                </SwiperSlide>
                
                <SwiperSlide>
                    <div className="testimonial-block">
                        <div className="inner-box">
                            <div className="author-image">
                                <img src="./assets/public/storage/testimonials/December2023/profile.png" alt />
                            </div>
                            <div className="text">“I have been using Adarsh Sales Pvt. Ltd for the past 15 years and I have always been satisfied with it.”</div>
                            <h5>Anindita Chakraborty</h5>
                            <div className="designation">Gestalt Interiors</div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="testimonial-block">
                        <div className="inner-box">
                            <div className="author-image">
                                <img src="./assets/public/storage/testimonials/December2023/profile.png" alt />
                            </div>
                            <div className="text">“It has been observed that materials supplied by Adarsh Sales Pvt. Ltd  to different Bank’s Empanelled contractors is good.”</div>
                            <h5>SBI</h5>
                            <div className="designation">Manager, Satna</div>
                        </div>
                    </div>
                </SwiperSlide>
              
            </Swiper>
        </>
    );
}
