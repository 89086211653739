import React from 'react'
import { Link } from "react-router-dom";
import FlushDoorPremium from './FlushDoorPremium';


const FlushDoors = () => {
    return (
        <>
            <section className="page-title">
                <div className="content" style={{ backgroundImage: 'url("./assets/public/website/assets/images/background/products.jpg")' }}>
                </div>
                <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                    <li>Product Details</li>
                </ul>
            </section>

            <div className="container">
                <div className="row">
                    <div className="col-lg-12 prodcdtls_outsection">
                        <div className="sky-tabs sky-tabs-pos-top-left sky-tabs-anim-flip sky-tabs-response-to-icons">
                            <input type="radio" name="sky-tabs" defaultChecked id="sky-tab1" className="sky-tab-content-1" />
                            <label className="switcher"><span><span><a to="#"><i className="icon-reorder" />Tabs</a></span></span></label>
                            <ul>
                                <li className="sky-tab-content-1">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="productdtls_sfvoutsection">
                                                    <div className="product-imgs">
                                                        <div className="img-display">
                                                            <div className="img-showcase">
                                                                <img src="../assets/ply/Gateway Pine FD.JFIF" alt="shoe image" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="productdtls_trgsection">
                                                    <h1 className="productzx_nmetextdesign">
                                                        Flush Door: Sandwich(IS:2202(PT-1)1999)
                                                    </h1>
                                                    <p className="lzpum_textdesign">
                                                        SANDWICH DOORS are the result of the innovation and dedication of our R&d
                                                        Department. These Doors are constructed with 3mm HDHMR FACIA Skin on Both sides
                                                        to provide a smoother finish. These doors provide the strength of normal flush doors
                                                        and the finish of an HDHMR at once. We also provide an extra lock rail on both sides as
                                                        extra support for modern technology locks. CNC Routing, DUCO, PU Paints are easily
                                                        done in this segment for a smoother finish and high quality.
                                                    </p>
                                                    {/* =======================points====== */}
                                                    <ul className="bthside_fgapareaprdctdtls">
                                                        <li>
                                                            {/* <img src="assets/images/resource/protect.png"> */}
                                                            <span>
                                                                10 Year Guarantee
                                                            </span>
                                                        </li>
                                                        <li>
                                                            {/* <img src="assets/images/resource/protect.png"> */}
                                                            <span>
                                                                Fully Water Proof
                                                            </span>
                                                            <img src="../assets/public/website/assets/images/resource/check-markr.png" className="redclrgap_design" />
                                                        </li>
                                                        {/* <li>
                                                            <span>
                                                                (IS:2202(PT-1)1999) </span>
                                                        </li> */}
                                                        <li>
                                                            <span>
                                                                Premium (IS:2202(PT-1)1999)
                                                            </span>
                                                            <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                                            <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                                            <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                                            <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                                            <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                                            <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                                        </li>
                                                    </ul>
                                                    {/* =======================points====== */}


                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 kftrs_outsection">
                                                <h1 className="rtrti_text">
                                                    Unique Features:
                                                </h1>
                                                <p className="sizethckns_design">
                                                </p>


                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="specitwo_outarea">

                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="specitwo_outarea">
                                                                        <p>➤ Boiling Waterproof </p>
                                                                        <p>➤ Smooth Surface</p>
                                                                        <p>➤ No warping or bending </p>
                                                                        <p>➤ Fungal, termite and borer treated</p>
                                                                        <p>➤ Superior Stability and durability</p>
                                                                        <p>➤ Absorbs less adhesive</p>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div className="specitwo_outarea">
                                                                        <p>➤ Easy to paint</p>
                                                                        <p>➤ Dimensional accuracy</p>
                                                                        <p>➤ High stability strength</p>
                                                                        <p>➤ High shock and buckling resistance</p>
                                                                        <p>➤ Chemically treated and seasoned as per BIS</p>
                                                                        <p>➤ Zero defects in flexure and edge loading </p>
                                                                        <p>➤ Properties of HDHMR and strength of plywood</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <h1 className="rtrti_text1">
                                                    Specifications
                                                </h1>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="specitwo_outarea">

                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="specitwo_outarea">
                                                                        <p>➤ Thickness available:- 35mm to 45mm</p>
                                                                        <p>➤ Size available:-  Available in all standard sizes                                                                            </p>
                                                                        <p>➤ Frame width:-  75mm</p>
                                                                        <p>➤  Lock rail width :- 75mm (both side)</p>
                                                                        <p>➤  Adhesive for bonding :- PF resin as per BIS standards.</p>
                                                                        <p>➤ Density :- 800-850 kg/cm3 </p>


                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <div className="specitwo_outarea">
                                                                        <p>➤ Moisture content :-  8-12% </p>
                                                                        <p>➤  Glue adhesion strength :- Excellent</p>
                                                                        <p>➤  Resistance to misuse :- Excellent</p>
                                                                        <p>➤ Water-resistance test :- Confirms to IS:2202(pt-1) 1999 </p>
                                                                        <p>➤ Warranty :- Any manufacturing defects as per warranty certificate. </p>
                                                                        <p>➤  IS STANDARDS :- IS:2202 (PT-1) 1999</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h1 className="rtrti_text2">
                                                    End Usage: Doors
                                                </h1>
                                                <ul className="spcgh_area">
                                                    <li>
                                                        <img src="../assets/public/storage/productends/January2024/HcNGY47FiqsJV3LAA7Ma.png" alt="Commercial Doors" />
                                                        <span>Commercial Doors</span>
                                                    </li>
                                                    <li>
                                                        <img src="../assets/public/storage/productends/January2024/L7LRXxKuA1XtG0yYOzkX.png" alt="Residential Doors" />
                                                        <span>Residential Doors</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>


                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <FlushDoorPremium />
        </>
    )
}

export default FlushDoors