import React from 'react'
import { Link } from "react-router-dom";


const Gallery = () => {
    return (
        <>

            <section className="page-title">
                <div className="content" style={{ backgroundImage: 'url("./assets/public/website/assets/images/background/products.jpg")' }}>
                </div>
                <ul className="page-breadcrumb">
                    <li><Link to="/">home</Link></li>
                    <li>Product</li>
                </ul>
            </section>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 related_productsection">
                        <h1 className="rprdcts_design">Our Gallery </h1>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p1.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p8.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p9.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p2.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p3.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p4.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p5.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p6.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="snghle_prodcutarea">
                                    <div className="ibxckclr_area">
                                        <img src="./assets/ply/p7.png" className="prdcxtimg_design" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Gallery