import React from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';


const Footer = () => {
  return (
    <>


      <div className="page-wrapper">

        <footer className="footer-style-two" style={{ backgroundImage: 'url("./assets//public/website/assets/images/background/footer-back.jpg")' }}>
          <div className="auto-container">
            {/* Widgets Section */}
            <div className="widgets-section">
              <div className="row clearfix">
                <div className="big-column col-lg-12">
                  <div className="row clearfix">
                    {/* Footer Column */}
                    <div className="footer-column col-lg-3">
                      <div className="footer-widget logo-widget">
                        <div className="widget-content">
                          <div className="logo">
                            {/* <Link to="/"><img src="./assets/public/website/assets/images/adarshply_logo - Copy.png" alt /></Link> */}
                            <Link to="/"><img src="./assets/ply/ply.png" alt  style={{width:"190px", height:"190px"}}/></Link>
                          </div>
                          <div className="text" style={{ fontSize: "16px" }}>Manufacturing plywood blended with reliability and made from world-class raw materials for a bond that lasts forever.</div>
                          {/* Social Box */}
                          <ul className="social-box">
                            <li>
                              <Link to="" className target="_blank">
                                <i className="fab fa-facebook-f" />
                              </Link>
                            </li>
                            <li>
                              <Link to="" className target="_blank">
                                <i className="fab fa-instagram" />
                              </Link>
                            </li>

                            <li>
                              <Link to="" className target="_blank">
                                <i className="fab fa-youtube-square" />
                              </Link>
                            </li>

                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* Footer Column */}
                    <div className="footer-column col-lg-2">
                      <div className="footer-widget links-widget">
                        <h5>Quick Links</h5>
                        <ul className="list-link">
                          <li><Link to="/">Home</Link></li>
                          <li><Link to="/about-us">About Us</Link></li>
                          <li><Link to="/contact-us">Contact Us</Link></li>

                        </ul>
                      </div>
                    </div>
                    {/* Footer Column */}
                    <div className="footer-column col-lg-2">
                      <div className="footer-widget links-widget">
                        <h5>Our Products</h5>
                        <ul className="list-link">
                          <li><Link to="/flush-doors">Flush Doors</Link></li>
                          <li><Link to="/block-boards">Block Boards</Link></li>
                          <li><Link to="/plywood">Ply Wood</Link></li>
                        </ul>
                      </div>
                    </div>
                    {/* Footer Column */}
                    <div className="footer-column col-lg-2">
                      <div className="footer-widget links-widget">
                        <h5>Tools</h5>
                        <ul className="list-link">
                          <li><Link to="/blogs">Blogs</Link></li>
                          <li><Link to="/careers">Careers</Link></li>
                          <li><Link to="/overview">Overview</Link></li>
                        </ul>
                      </div>
                    </div>
                    {/* Footer Column */}
                    <div className="footer-column col-lg-3">
                      <div className="footer-widget links-widget">
                        <h5>Contact Us</h5>
                        <div className="subscribe-form-two">
                          <ul className="footer-widget__contact-list list-unstyled clearfix">
                            <li>
                              <div className="icon downaddress_icon1">
                                <i className="fas fa-map-marker mapphn_icondesign" />
                              </div>
                              <div className="text">
                                <h5>Address</h5>
                                <a href='https://maps.app.goo.gl/w16QjmcwF8trMZ8n8?g_st=ac' target="_blank" >
                                  <p style={{ fontSize: "14px" }}>
                                    Wood Work , In Front of Sitaram Petrol Pump, Rewa Road Satna (M.P.)
                                  </p></a>
                              </div>
                            </li>

                            <li>
                              <div className="icon downaddress_icon2">
                                <i className="fas fa-phone-volume mapphn_icondesign" />
                              </div>
                              <div className="text">
                                <h5 style={{ fontSize: "16px" }}>Call Us</h5>
                                <p style={{ fontSize: "14px" }}>
                                  <Link to="tel:+91 9977155591">

                                    +91- 9977155591
                                  </Link>
                                </p>
                              </div>
                            </li>
                            <li>
                              <div className="icon downaddress_icon2">
                                <i className="fas fa-envelope mapphn_icondesign" />
                              </div>
                              <div className="text">
                                <h5 style={{ fontSize: "14px" }}>Send Email</h5>
                                <p style={{ fontSize: "14px" }}>
                                  <Link to="mailto:aspl2009@yahoo.in">aspl2009@yahoo.in
                                  </Link>
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="footer-bottom">
            <div className="auto-container">
              {/* <div className="getconsul_areabtn">
                <button className="gcsul_btndesign" data-toggle="modal" data-target="#myModal">
                  Get Consultation
                </button>
              </div> */}
              <a href='tel:+91 8982471422' style={{ color: "white" }}>
                <div className="copyright">
                  © 2024 Adarsh Sales Pvt. Ltd. All Rights Reserved
                </div></a>
            </div>
          </div>
        </footer>

      </div>
      <WhatsappIcon />



    </>
  )
}

export default Footer