import React from 'react'
import { Link } from "react-router-dom";


const BlockBoards = () => {
  return (
    <>
      <div className=''>
        <section className="page-title">
          <div className="content" style={{ backgroundImage: 'url("./assets/public/website/assets/images/background/products.jpg")' }}>
          </div>
          <ul className="page-breadcrumb">
            <li><Link to="/">home</Link></li>
            <li>Product Details</li>
          </ul>
        </section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 prodcdtls_outsection">
              <div className="sky-tabs sky-tabs-pos-top-left sky-tabs-anim-flip sky-tabs-response-to-icons">
                <input type="radio" name="sky-tabs" defaultChecked id="sky-tab1" className="sky-tab-content-1" />
                <label className="switcher"><span><span><a to="#"><i className="icon-reorder" />Tabs</a></span></span></label>
                <ul>
                  <li className="sky-tab-content-1">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="productdtls_sfvoutsection">
                            <div className="product-imgs">
                              <div className="img-display">
                                <div className="img-showcase">
                                  <img src="../assets/ply/Block Boards.jpg" alt="shoe image" />
                                  {/* <img src="../assets/public/storage/plywoodproducts/February2024/qqSFxjWeMG2HvepQYubI.png" alt="shoe image" /> */}
                                  {/* <img src="../assets/public/storage/plywoodproducts/February2024/BP2BTT6dgD5PvSC2Czwj.jpg" alt="shoe image" /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="productdtls_trgsection">
                            <h1 className="productzx_nmetextdesign">
                              Sandwich Blockboards
                            </h1>
                            <p className="lzpum_textdesign">
                              SANDWICH Blockboard is ideal for home and office furniture, segments,
                              and shades. The synthetically treated and prepared imported pine wood is
                              handled in our processing plant.
                              It has been explicitly made with a magnificent nail holding limit that
                              permits the Blockboard to be collected into any furniture easily.

                            </p>
                            {/* =======================points====== */}
                            <ul className="bthside_fgapareaprdctdtls">
                              <li>
                                {/* <img src="assets/images/resource/protect.png"> */}
                                <span>
                                  Supreme Boards
                                </span>
                              </li>
                              <li>
                                {/* <img src="assets/images/resource/protect.png"> */}
                                <span>
                                  Boiling Water Proof
                                </span>
                                <img src="../assets/public/website/assets/images/resource/check-markr.png" className="redclrgap_design" />
                              </li>
                              <li>
                                {/* <img src="assets/images/resource/protect.png"> */}
                                <span>
                                  19mm & 25mm </span>
                              </li>
                              <li>
                                <span>
                                  Excellent
                                </span>
                                <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                                <img src="./assets/public/website/assets/images/resource/star.png" className="skgap_design1" />
                              </li>
                            </ul>
                            {/* =======================points====== */}


                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 kftrs_outsection">

                          <h1 className="rtrti_text1">
                            Unique Features
                          </h1>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="specitwo_outarea">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="specitwo_outarea">
                                      <p>➤ Boiling Water Proof</p>
                                      <p>➤ Smooth surface </p>
                                      <p>➤ Face thickness 3mm or 5mm</p>
                                      <p>➤ Available with full-thickness</p>
                                      <p>➤  Chemically treated and seasoned as
                                        per BIS</p>
                                      <p>➤ No warping or bending</p>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="specitwo_outarea">
                                      <p>➤ Fungal, termite and borer treated</p>
                                      <p>➤ Superior Stability and durability</p>
                                      <p>➤ Absorbs less adhesive</p>
                                      <p>➤ Easy to paint</p>
                                      <p>➤ Dimensional accuracy  </p>
                                      <p>➤  High stability strength</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h1 className="rtrti_text1">
                            Specifications
                          </h1>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="specitwo_outarea">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <div className="specitwo_outarea">
                                      <p>➤ Thickness available :- 19mm & 25mm</p>
                                      <p>➤ Size available :-  Available in all standard
                                        sizes </p>
                                      <p>➤ Face :- HDHMR</p>
                                      <p>➤ Filler :- Pinewood</p>
                                      <p>➤ Adhesive for bonding :-  PF resin as per BIS
                                        standards.</p>

                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="specitwo_outarea">
                                      <p>➤ Density :- Above 700 kg/cm3
                                      </p>
                                      <p>➤ Moisture content :-  6-8%</p>
                                      <p>➤ Glue adhesion strength :-   Excellent </p>
                                      <p>➤  Resistance to misuse :- Excellent </p>
                                      <p>➤ Warranty Any :-  manufacturing defects
                                        as per warranty certificate.</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h1 className="rtrti_text2">
                            End Usages:- Furniture, Partitions, Racks, Shelves, Shutters Etc
                          </h1>
                          <ul className="spcgh_area">
                            <li>
                              <img src="../assets/public/storage/productends/January2024/HcNGY47FiqsJV3LAA7Ma.png" alt="Commercial Doors" />
                              <span>Commercial Block Boards</span>
                            </li>
                            <li>
                              <img src="../assets/public/storage/productends/January2024/L7LRXxKuA1XtG0yYOzkX.png" alt="Residential Doors" />
                              <span>Residential Block Boards</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>



      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 aboutus_outsection">
              <div className="row">
                <div className="col-lg-5">
                  <div className="leftimg_outsection">
                    <img src="./assets/ply/SUPREME BOARDS.jpg" className="abtus_imgdesign" />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="righttime_outsection">
                    <br />
                    <br />
                    <h1 className="productzx_nmetextdesign">
                      SUPREME BOARDS
                    </h1>
                    <p className="aboutus_content">
                    </p>
                    <p className="aboutus_content">SUPREME Grade Block boards are waterproof block boards constructed with PF
                      Resin, according to IS norms. Fillers inside these boards are filled to spread the
                      resin equally which provide excellent bonding and strength.</p>



                  </div>
                </div>

                <div className="col-lg-12 kftrs_outsection">
                  <h1 className="rtrti_text">
                    Unique features:
                  </h1>
                  <p className="sizethckns_design">
                  </p>


                  <div className="row">
                    <div className="col-lg-12">
                      <div className="">

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="">
                              <p>➤  Boiling Water Proof  </p>
                              <p>➤  Smooth surface  </p>
                              <p>➤   Available with full-thickness </p>
                              <p>➤  Chemically treated and seasoned
                                as per BIS
                              </p>




                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="">
                              <p>➤   No warping or bending </p>
                              <p>➤   Fungal, termite and borer treated </p>
                              <p>➤   Superior Stability and durability </p>
                              <p>➤   Superior Stability and durability </p>
                              <p>➤  High stability strength  </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <h1 className="rtrti_text1">
                    Specifications
                  </h1>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="">

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="">
                              <p>➤ Thickness available  : 19mm & 25mm
                              </p>
                              <p>➤ Size available  : Available in all standard sizes </p>
                              <p>➤  Face  : Gurjan
                              </p>
                              <p>➤  Filler :  Pinewood</p>
                              <p>➤  Adhesive for bonding  : PF resin as per BIS standards.</p>


                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="">
                              <p>➤ Density  : Above 700 kg/cm3 </p>
                              <p>➤ Moisture content :  6-8% </p>
                              <p>➤ Glue adhesion strength  : Excellent </p>
                              <p>➤Resistance to misuse  : Excellent  </p>
                              <p>➤ Warranty : Any manufacturing defects as per
                                warranty certificate. </p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h1 className="rtrti_text2">
                    END USAGE:
                  </h1>
                  <ul className="spcgh_area">
                    <li>
                      <img src="../assets/public/storage/productends/January2024/HcNGY47FiqsJV3LAA7Ma.png" alt="Commercial Doors" />
                      <span> FURNITURE, PARTITIONS, RACKS, SHELVES, SHUTTERS ETC</span>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>



      </section>

    </>
  )
}

export default BlockBoards