import React from 'react'
import SliderTestimonial from '../../component/slidercomponent/SliderTestimonial'
import { Link } from "react-router-dom";


const Testimonial = () => {
    return (
        <>

            <section className="testimonial-section">
                <div className="image-layer" style={{ backgroundImage: 'url(./assets/public/website/assets/images/background/testimonials-back.jpg)' }}>
                </div>
                <div className="auto-container">
                    <div className="row clearfix">

                        <div className="title-column col-lg-4 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <h2 className="thte_textdesign">The biggest thing <br /> we build is trust.</h2>


                                <div className="button-box">
                                    <Link className="btn-style-one theme-btn" to="/about-us"><span className="txt">More About Us</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="carousel-column col-lg-8 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="single-item-carousel owl-theme">

                                    <SliderTestimonial />


                                    {/* <div className="testimonial-block">
                                        <div className="inner-box">
                                            <div className="author-image">
                                                <img src="./assets/public/storage/testimonials/December2023/afvKJRWYfPLDNM7E2pTY.png" alt />
                                            </div>
                                            <div className="text">“While using a product we look forward to longevity, quality, after-sales service, and price. Sylvan gateway flush doors and plywoods meet all the Standards(BIS).”</div>
                                            <h5>Bijay Jain</h5>
                                            <div className="designation">Arrjavv Builder Private Limited</div>
                                        </div>
                                    </div>


                                    <div className="testimonial-block">
                                        <div className="inner-box">
                                            <div className="author-image">
                                                <img src="./assets/public/storage/testimonials/December2023/szVgyZYCKNPhnjEIVhw8.png" alt />
                                            </div>
                                            <div className="text">““SYLVAN PLY”, now a symbolic name in Ply industry who will illuminate the world of ply with the good quality and finish of their product . Specially, ‘Sylvan Z+ Premium’ and ‘Marine Oceanic’ Plywood is better for their intrisic strength and long lasting value.”</div>
                                            <h5>Abhijit Dhar</h5>
                                            <div className="designation">Dhar &amp; Associates Pvt. Ltd.</div>
                                        </div>
                                    </div>


                                    <div className="testimonial-block">
                                        <div className="inner-box">
                                            <div className="author-image">
                                                <img src="./assets/public/storage/testimonials/December2023/n2ogBzLsA2Tn5iXRPwNu.png" alt />
                                            </div>
                                            <div className="text">“I have been using Sylvan Ply for the past 7 years and I have always been satisfied with it.”</div>
                                            <h5>Anindita Chakraborty</h5>
                                            <div className="designation">Gestalt Interiors</div>
                                        </div>
                                    </div>


                                    <div className="testimonial-block">
                                        <div className="inner-box">
                                            <div className="author-image">
                                                <img src="./assets/public/storage/testimonials/December2023/oSiKryH2RWhhwPMHOUS8.png" alt />
                                            </div>
                                            <div className="text">“It has been observed that materials supplied by M/s Sylvan Plyboard (India) Limited to different Bank’s Empanelled contractors is good.”</div>
                                            <h5>SBI</h5>
                                            <div className="designation">Manager, Lho Kolkata</div>
                                        </div>
                                    </div>


                                    <div className="testimonial-block">
                                        <div className="inner-box">
                                            <div className="author-image">
                                                <img src="./assets/public/storage/testimonials/April2024/yBLE6oBFSquSXuiKiGTe.jpg" alt />
                                            </div>
                                            <div className="text">“14 saal ka sammandh,  Quality uss se kafi jayada. Yeh sach hain, break Na banne ka wada. Kafi purani ye dosti meri sylvan ke sath, Ply ho toh Sylvan iss may nahi or koyee baat.”</div>
                                            <h5>Souvik Banerjee</h5>
                                            <div className="designation">Interior Designer and Decorator</div>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial