import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";



export default function SliderProcess() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 3,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >

                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h1.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h2.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
              
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h3.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h4.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h5.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h6.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h7.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h8.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h9.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h10.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h11.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h12.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
                <SwiperSlide>
                    <li className="slide-item psection_ingallery">
                        <figure className="image-box"><a to="/gallery"><img src="./assets/ply/h13.png" alt /></a></figure>
                    </li>
                </SwiperSlide>
            </Swiper>
        </>
    );
}
