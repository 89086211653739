import React from 'react'
import { Link } from "react-router-dom";


const LatestBlogs = () => {
    return (
        <>
            <section className="news-section">
                <div className="auto-container">

                    <div className="banner">
                        <h1 className="textabtn_Design">Latest Blogs</h1>
                    </div>
                    <div className="row clearfix">

                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog">
                                        <img src="./assets/public/storage/blogs/August2024/TvFc9zVfzp2dlXlZIJiA.jpeg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">20-08-2024</div>
                                    <h4 className="content_headingtext"><Link to="/blog">Unleashing Creativity: Adarsh Sales Pvt. Ltd Guide to Unique Home and Office Design</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>

                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog">
                                        <img src="./assets/public/storage/blogs/August2024/executive.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">24-08-2024</div>
                                    <h4 className="content_headingtext"><Link to="/blog">Adarsh Sales Pvt. Ltd Manufacturing Process: A Journey of Craftsmanship</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div className="news-block col-lg-4 col-md-6 col-sm-12">
                            <div className="inner-box wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="image">
                                    <Link to="/blog">
                                        <img src="./assets/public/storage/blogs/August2024/flexible plywood 1.jpg" alt />
                                    </Link>
                                </div>
                                <div className="lower-content">
                                    <div className="post-date">26-08-2024</div>
                                    <h4 className="content_headingtext"><Link to="/blog">Adarsh Sales Pvt. Ltd Robusta Premium Plus: The Pinnacle of Plywood Performance</Link>
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default LatestBlogs